import React, { useEffect } from 'react'
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import { Avatar, ListItem, ListItemAvatar, ListItemText, Typography } from '@mui/material';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import { styled } from '@mui/material/styles';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import PlaylistAddCheckIcon from '@mui/icons-material/PlaylistAddCheck';
import TaskIcon from '@mui/icons-material/Task';
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import ErrorIcon from '@mui/icons-material/Error';
import AssignmentLateIcon from '@mui/icons-material/AssignmentLate';
import axios from 'axios';
import _ from 'lodash'
import { Gantt, Task, EventOption, StylingOption, ViewMode, DisplayOption } from 'gantt-task-react';
import "gantt-task-react/dist/index.css";
import ButtonAppBar from './ButtonAppBar';




const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));

function Chart() {
    const [value, setValue] = React.useState('one');
    const [token, setToken] = React.useState(null);
    const [data, setData] = React.useState(null);
    const [list, setList] = React.useState([]);
    const [tasks, setTask] = React.useState([]);
    const [count, setCount] = React.useState(1);



    React.useEffect(() => {
        const formData = new FormData();
        formData.append('client_id', '3MVG9CecKwYCDceSbZrT2dbknOgYF7nLzXm9OgP4SCMusIHBYmJEv5hiEeqAH2DwahAaG10zvTzptzlhHZ0Pc');
        formData.append('client_secret', 'D4348317F2BEEE3E514BFC378D769844F266579270D637BC340315BFC3AD0A58');
        formData.append('grant_type', 'password');
        formData.append('username', 'anu@green.com');
        formData.append('password', 'sales@2023yusDy6dE8cC18i0eehU14EyJI');

        axios.post('https://gwintech-dev-ed.develop.my.salesforce.com/services/oauth2/token', formData).then((res) => {
            setToken(res.data.access_token)
        }).catch((error) => { })
    })



    React.useEffect(() => {
        if (token) {
            let config = {
                method: 'post',
                maxBodyLength: Infinity,
                url: 'https://gwintech-dev-ed.develop.my.salesforce.com/services/apexrest/fetchJobSummary',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                    'Cookie': 'BrowserId=b7VVuf3rEe25FTECSZ-eMQ; CookieConsentPolicy=0:1; LSKey-c$CookieConsentPolicy=0:1'
                }
            };

            axios.request(config)
                .then((response) => {

                    setData(response.data)
                })
                .catch((error) => {
                    console.log(error);
                });
        }

    }, [token])

    useEffect(() => {
        if (data) {
            if (value == "one") {
                let filtered_array = _.filter(
                    data.records, function (o) {
                        return o.status == "Completed";
                    }
                );
                setList(filtered_array)
            } else if (value == "two") {
                let filtered_array = _.filter(
                    data.records, function (o) {
                        return o.status == "Completed With Warnings";
                    }
                );
                setList(filtered_array)

            } else if (value == "three") {
                let filtered_array = _.filter(
                    data.records, function (o) {
                        return o.status == "Completed With Errors";
                    }
                );
                setList(filtered_array)

            } else {
                let filtered_array = _.filter(
                    data.records, function (o) {
                        return o.status == "Failed";
                    }
                );
                setList(filtered_array)

            }
        }

    }, [data, value])

    React.useEffect(() => {

        if (list.length > 0) {
            setTask(list.map((n) => {
                let date = n.startDate.split(",")[0]
                let dt = date.split("/")
                let date2 = n.completedDate.split(",")[0]
                let dt2 = date2.split("/")
                return {
                    start: new Date(Number(dt[2]), Number(dt[1]), Number(dt[0])),
                    end: new Date(Number(dt2[2]), Number(dt2[1]), Number(dt2[0])),
                    name: n.name,
                    id: n.salesforceId,
                    type: n.noOfRecords,
                    progress: n.noOfRecords,
                    isDisabled: true,
                    styles: { progressColor: '#ffbb54', progressSelectedColor: '#ff9e0d' },
                }


            }))
        }
    }, [data, list])

 
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <>
            {tasks.length > 0 && <div style={{ backgroundColor: '#F5F5F5' }} marginTop={5}>
                <CssBaseline />
                <ButtonAppBar />
                <br /> <br /> <br /> <br /> <br />
                <Container maxWidth="xl">
                    <Box sx={{ bgcolor: '#1FA49D', height: '300px', borderRadius: '36px' }} >
                        <br />
                        <Typography variant="h4" component="h4" marginLeft={5} style={{ fontFamily: 'Poppins', color: 'white' }}>
                            Integra
                        </Typography>
                        <Typography variant="h4" component="h4" marginLeft={5} style={{ fontFamily: 'Poppins', color: 'white' }}>
                            Integration event summary
                        </Typography>
                        <br />
                        <br />
                        <br />
                        <Box sx={{ flexGrow: 1 }}>
                            <Grid container spacing={2} marginLeft={2}>
                                <Grid item xs={2.2} style={{ backgroundColor: 'white', borderRadius: '26px' }}>
                                    <ListItem>
                                        <ListItemAvatar>
                                            <Avatar sx={{ width: 56, height: 56 }} style={{ backgroundColor: "#1FA49D" }}>
                                                <PlaylistAddCheckIcon />
                                            </Avatar>
                                        </ListItemAvatar>
                                        <ListItemText>
                                            <Typography variant="h5" component="h5" style={{ fontFamily: 'Poppins', fontWeight: 'bold' }} marginLeft={2}>
                                                {data?.totaljobs}
                                            </Typography>
                                            <Typography variant="h8" component="h8" style={{ fontFamily: 'Poppins', color: "#1FA49D" }} marginLeft={2}>
                                                Total Tasks
                                            </Typography>
                                        </ListItemText>
                                    </ListItem>
                                    <br />
                                </Grid>
                                <Grid item xs={2.2} style={{ backgroundColor: 'white', borderRadius: '26px' }} marginLeft={2}>
                                    <ListItem>
                                        <ListItemAvatar>
                                            <Avatar sx={{ width: 56, height: 56 }} style={{ backgroundColor: "#1FA49D" }}>
                                                <TaskIcon />
                                            </Avatar>
                                        </ListItemAvatar>
                                        <ListItemText>
                                            <Typography variant="h5" component="h5" style={{ fontFamily: 'Poppins', fontWeight: 'bold' }} marginLeft={2}>
                                                {data?.completedjobs}
                                            </Typography>
                                            <Typography variant="h8" component="h8" style={{ fontFamily: 'Poppins', color: "#1FA49D" }} marginLeft={2}>
                                                Completed
                                            </Typography>
                                        </ListItemText>
                                    </ListItem>
                                    <br />
                                </Grid>

                                <Grid item xs={2.2} style={{ backgroundColor: 'white', borderRadius: '26px' }} marginLeft={2}>
                                    <ListItem>
                                        <ListItemAvatar>
                                            <Avatar sx={{ width: 56, height: 56 }} style={{ backgroundColor: "#1FA49D" }}>
                                                <ReportProblemIcon />
                                            </Avatar>
                                        </ListItemAvatar>
                                        <ListItemText>
                                            <Typography variant="h5" component="h5" style={{ fontFamily: 'Poppins', fontWeight: 'bold' }} marginLeft={2}>
                                                {data?.completedwithwarnings}
                                            </Typography>
                                            <Typography variant="h8" component="h8" style={{ fontFamily: 'Poppins', color: "#1FA49D" }} marginLeft={2}>
                                                Completed w/ warnings
                                            </Typography>
                                        </ListItemText>
                                    </ListItem>
                                    <br />
                                </Grid>

                                <Grid item xs={2.2} style={{ backgroundColor: 'white', borderRadius: '26px' }} marginLeft={2}>
                                    <ListItem>
                                        <ListItemAvatar>
                                            <Avatar sx={{ width: 56, height: 56 }} style={{ backgroundColor: "#1FA49D" }}>
                                                <ErrorIcon />
                                            </Avatar>
                                        </ListItemAvatar>
                                        <ListItemText>
                                            <Typography variant="h5" component="h5" style={{ fontFamily: 'Poppins', fontWeight: 'bold' }} marginLeft={2}>
                                                {data?.completedwitherrors}
                                            </Typography>
                                            <Typography variant="h9" component="h9" style={{ fontFamily: 'Poppins', color: "#1FA49D" }} marginLeft={2}>
                                                Completed w/ Error
                                            </Typography>
                                        </ListItemText>
                                    </ListItem>
                                    <br />
                                </Grid>

                                <Grid item xs={2.2} style={{ backgroundColor: 'white', borderRadius: '26px' }} marginLeft={2}>
                                    <ListItem>
                                        <ListItemAvatar>
                                            <Avatar sx={{ width: 56, height: 56 }} style={{ backgroundColor: "#1FA49D" }}>
                                                <AssignmentLateIcon />
                                            </Avatar>
                                        </ListItemAvatar>
                                        <ListItemText>
                                            <Typography variant="h5" component="h5" style={{ fontFamily: 'Poppins', fontWeight: 'bold' }} marginLeft={2}>
                                                {data?.failedjobs}
                                            </Typography>
                                            <Typography variant="h8" component="h8" style={{ fontFamily: 'Poppins', color: "#1FA49D" }} marginLeft={2}>
                                                Failed
                                            </Typography>
                                        </ListItemText>
                                    </ListItem>
                                    <br />
                                </Grid>
                            </Grid>
                        </Box>

                    </Box>
                </Container>
                <Container maxWidth="xl">
                    <Box sx={{ bgcolor: '#F5F5F5', height: '100vh', }} >
                        <br />
                        <Typography variant="h4" component="h4" marginLeft={5} style={{ fontFamily: 'Poppins' }}>
                            Event timeline
                        </Typography>
                        <br />
                        <Tabs
                            value={value}
                            onChange={handleChange}
                            textColor="gray"
                            indicatorColor="secondary"
                            aria-label="secondary tabs example"
                            fontFamily="Poppins"




                        >
                            <Tab value="one" label="Completed" style={{ fontFamily: 'Poppins', fontWeight: 'bold', width: "310px" }} />
                            <Tab value="two" label="Completed with Warnings" style={{ fontFamily: 'Poppins', fontWeight: 'bold', width: "310px" }} />
                            <Tab value="three" label="Completed with Error" style={{ fontFamily: 'Poppins', fontWeight: 'bold', width: "310px" }} />
                            <Tab value="four" label="Failed" style={{ fontFamily: 'Poppins', fontWeight: 'bold', width: "310px" }} />
                        </Tabs>
                        <br />
                        <div><Gantt tasks={tasks} /></div>

                    </Box>
                </Container>
            </div>
            }
        </>

    )
}

export default Chart