import * as React from 'react';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Container, Typography } from '@mui/material';
import axios from 'axios';
import ButtonAppBar from './ButtonAppBar';


const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

function createData(name, calories, fat, carbs, protein) {
  return { name, calories, fat, carbs, protein };
}

const rows = [
  createData('Frozen yoghurt', 159, 6.0, 24, 4.0),
  createData('Ice cream sandwich', 237, 9.0, 37, 4.3),
  createData('Eclair', 262, 16.0, 24, 6.0),
  createData('Cupcake', 305, 3.7, 67, 4.3),
  createData('Gingerbread', 356, 16.0, 49, 3.9),
];

export default function CustomizedTables() {
  const [token, setToken] = React.useState(null);
  const [list, setList] = React.useState([]);
   React.useEffect(()=>{

    const formData = new FormData();
    formData.append('client_id', '3MVG9CecKwYCDceSbZrT2dbknOgYF7nLzXm9OgP4SCMusIHBYmJEv5hiEeqAH2DwahAaG10zvTzptzlhHZ0Pc');
    formData.append('client_secret', 'D4348317F2BEEE3E514BFC378D769844F266579270D637BC340315BFC3AD0A58');
    formData.append('grant_type', 'password');
    formData.append('username', 'anu@green.com');
    formData.append('password', 'sales@2023yusDy6dE8cC18i0eehU14EyJI');

  axios.post('https://gwintech-dev-ed.develop.my.salesforce.com/services/oauth2/token',formData).then((res)=>{
    setToken(res.data.access_token)
  }).catch((error)=>{})
})

React.useEffect(()=>{
   if(token){
 
  let config = {
    method: 'post',
    maxBodyLength: Infinity,
    url: 'https://gwintech-dev-ed.develop.my.salesforce.com/services/apexrest/fetchJobSchedule',
    headers: { 
      'Content-Type': 'application/json', 
      'Authorization': `Bearer ${token}`, 
      'Cookie': 'BrowserId=b7VVuf3rEe25FTECSZ-eMQ; CookieConsentPolicy=0:1; LSKey-c$CookieConsentPolicy=0:1'
    }
  };
  
  axios.request(config)
  .then((response) => {
    setList(response.data.records)
  })
  .catch((error) => {
    console.log(error);
  });
}

},[token])

  return (
  <>
    <div style={{ backgroundColor: '#F5F5F5'}}>
    <ButtonAppBar/>
      <br/>
      <br/>
      <br/>
      <br/>
      <br/>
      <Typography variant="h4" component="h4" marginLeft={15} style={{fontFamily:'Poppins'}}>
      Scheduled Alerts Detailed Report
      </Typography>
      <br/>
      <br/>
      <br/>

      <Container maxWidth="lg">
        <TableContainer component={Paper} sx={{borderRadius: '16px'}} >
          <Table sx={{ minWidth: 700, height: "70px" }} aria-label="customized table">
            <TableHead sx={{ height: "80px"}}>
              <TableRow >
                <StyledTableCell style={{fontSize:"18px", backgroundColor: '#1FA49D',fontFamily:'Poppins' }} align="center">Integration Name</StyledTableCell>
                <StyledTableCell style={{fontSize:"18px", backgroundColor: '#1FA49D',fontFamily:'Poppins' }} align="center">Previous Schedule run</StyledTableCell>
                <StyledTableCell style={{fontSize:"18px", backgroundColor: '#1FA49D',fontFamily:'Poppins' }} align="center">Next scheduled run</StyledTableCell>
                <StyledTableCell style={{fontSize:"18px", backgroundColor: '#1FA49D',fontFamily:'Poppins' }} align="center">Schedule end</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {list.map((row) => (
                <StyledTableRow key={row.name}>
                  <StyledTableCell style={{fontSize:"16px",fontFamily:'Poppins' }} align="center">{row.name}</StyledTableCell>
                  <StyledTableCell style={{fontSize:"16px",fontFamily:'Poppins' }}align="center">{row.prevrun.split(",")[0]}</StyledTableCell>
                  <StyledTableCell style={{fontSize:"16px",fontFamily:'Poppins' }}align="center">{row.nextschedulerun.split(",")[0]}</StyledTableCell>
                  <StyledTableCell style={{fontSize:"16px",fontFamily:'Poppins' }}align="center">{row.endDate}</StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Container>
    </div></>
  );
}