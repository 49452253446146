import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import {  useNavigate } from 'react-router-dom';

export default function ButtonAppBar() {
  const navigate = useNavigate();
  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="fixed" style={{backgroundColor:'#1FA49D'}} >
        <Toolbar>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
          INTEGRA INTEGRATION EVENT SUMMARY
          </Typography>
          <Button color="inherit" onClick={()=>{navigate("/")}}>DashBoard</Button>
          <Button color="inherit" onClick={()=>{navigate("/list")}}>List</Button>
        </Toolbar>
      </AppBar>
    </Box>
  );
}
