import {
  Routes,
  Route,
  useNavigationType,
  useLocation,
} from "react-router-dom";



import CustomTableCell from "./pages/CustomTableCell";
import Chart from "./pages/chart";


function App() {
  return (
    <Routes>
      <Route path="/" element={<Chart/>} />
     
      <Route
        path="/list"
        element={<CustomTableCell/>}
      />
    </Routes>
  );
}
export default App;
